<template>
    <div class="d-flex flex-grow-1">

        <v-main class="align-center ma-auto w-full" style="max-width: 480px">
            <v-card :loading="isLoading">
                <form ref="form" method="post" action="/api/passport/logout" lazy-validation>
                    <input type="hidden" name="logoutId" :value="logoutId" />
                    <v-card-title>
                        {{ $t('views.passport.logout.title') }}
                    </v-card-title>
                    <v-card-text>
                        {{ $t('views.passport.logout.alert') }}
                    </v-card-text>
                    <v-card-actions>
                        <v-container class="mt-2">
                            <v-row align="center" justify="center" dense>
                                <v-col>
                                    <v-btn type="submit" color="primary" x-large block :loading="isSubmitting" :disabled="isSubmitting">
                                        {{ $t('views.passport.logout.yes') }}
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn color="default" x-large block :loading="isSubmitting" :disabled="isSubmitting" @click="onClickNo">
                                        {{ $t('views.passport.logout.no') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-actions>
                </form>
            </v-card>
        </v-main>
    </div>
</template>

<script>
import { getLogoutViewModel } from '@/api/passport';
import utils from '@/utils';

export default {
    data () {
        return {
            isLoading: false,
            isSubmitting: false,
            logoutId: null
        };
    },
    methods: {
        onClickNo () {
            history.back();
        }
    },
    async created () {
        this.isLoading = true;
        var logoutId = utils.route.getQueryString(this.$route, 'logoutId');
        var vm = await getLogoutViewModel({ logoutId });
        this.logoutId = vm.logoutId;

        if (!vm.showLogoutPrompt) {
            this.$nextTick(() => {
                this.$refs.form.submit();
                this.isLoading = false;
            });
        } else {
            this.isLoading = false;
        }
    }
};
</script>
